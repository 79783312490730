//--------------------------------
// Action Bar
//--------------------------------
.actionbar {
	background-color : oklch(var(--actionbar-color));
	position         : absolute;
	top              : 0;
	left             : 0;
	right            : 0;
	width            : 100%;
	margin           : 0;
	padding          : 1rem;
	opacity          : 0;
	transition       : opacity 300ms ease-in-out;
	z-index          : 1;
	display          : flex;
	gap              : 1rem;
	backdrop-filter  : blur(3px);

	// always show on touch devices
	@media (hover: none) {
		opacity : 1;
	}
	@media (pointer: coarse) {
		opacity : 1;
	}

    button {
		appearance : none;
		border     : none;
		cursor     : pointer;

		opacity          : 0.8;
		background-color : oklch(var(--actionbar-icon-color));
		width            : var(--actionbar-icon-size);
		height           : var(--actionbar-icon-size);
		transition       : scale 300ms ease-in-out;

		&:hover {
			opacity : 1;
			scale   : 1.15;
        }

		&.download {
			mask-image : var(--icon-download);
		}
		&.upload {
			mask-image  : var(--icon-upload-alt);
			margin-left : auto;
		}
		&.edit {
			mask-image : var(--icon-edit);
		}
		&.add-folder {
			mask-image : var(--icon-folder-add);
		}
		&.featured {
			mask-image : var(--icon-not-featured);
		}
		&.clear {
			mask-image : var(--icon-clean);
		}
		&.trash {
			mask-image : var(--icon-trash);
		}
		&.macro {
			mask-image : var(--icon-macro);
		}
		&.protect {
			mask-image : var(--icon-lock);
		}
		&.links {
			mask-image : var(--icon-link);
		}
		&.move {
			cursor     : grab;
			mask-image : var(--icon-bars);
			margin     : 0 auto;

			&:active {
				cursor : grabbing;
			}
		}

		&[disabled] {
			opacity : 0.2;
			cursor  : not-allowed;

			&:hover {
				scale : 1;
			}
		}
    }

    // &.fill {
    //     position : absolute;
    //     top      : 0;
    //     bottom   : 0;
    //     left     : 0;
    //     right    : 0;
    //     height   : 100%;
    //     padding  : 0;

    //     button {
	// 		margin      : 0;
	// 		width       : 50%;
	// 		height      : 40px;
	// 		line-height : 40px;
	// 		font-size   : 1.3rem;
    //     }
    // }
}

.featured {
	button.featured {
		mask-image : var(--icon-featured);
	}
}
.cleared-cache {
	button.clear {
		mask-image : var(--icon-success);
	}
}

.image-field {
    .move {
        display : none;
    }
}

div:not(.empty):hover {
    > .actionbar {
        opacity : 1;
    }
}

.dz-error,.dz-processing {
    .actionbar {
        display : none!important;
    }
}

