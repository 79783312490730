//------------------------------
// Color Field
//------------------------------
input[type="color"] {
    appearance       : none;
    border           : none;
    width            : 64px;
    height           : 64px;
    padding          : 0;
    box-shadow       : 0 1px 5px oklch(var(--totalform-darkgray) / 0.5);
    background-color : transparent;
    border-radius    : 50% !important;
    margin-bottom    : 0.5rem;

    &::-webkit-color-swatch-wrapper {
        padding : 0;
    }

    &::-webkit-color-swatch {
        border        : none;
        border-radius : 50%;
    }

    + .form-group-icon {
        display : none;
    }

	&:focus {
        border     : none;
        box-shadow : 0 1px 5px oklch(var(--totalform-darkgray) / 0.5);
	}
}
.color-field {
	width : auto;

    .form-group {
        box-shadow : none;
    }
}