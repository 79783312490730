//------------------------------
// Toggle Field
//------------------------------
.toggle-field {
	width : auto;

	.switch {
		position  : relative;
		min-width : 50px;
    }
    input[type="checkbox"] {
        opacity : 0;     // hides checkbox
        height  : 30px;

        & + label {
            position         : absolute;
            top              : 0;
            left             : 0;
            display          : inline-block;
            user-select      : none;
            transition       : .4s ease;
            height           : 30px;
            width            : 50px;
            border           : 1px solid oklch(var(--totalform-border-color));
            border-radius    : 60px;
            text-indent      : -9999px;
            background-color : oklch(var(--totalform-background));

            -webkit-tap-highlight-color: transparent;

            &:before {
                content       : "";
                position      : absolute;
                display       : block;
                height        : 30px;
                width         : 51px;
                top           : 0;
                left          : -1px;
                border-radius : 60px;
                transition    : .2s cubic-bezier(.24, 0, .5, 1);
            }

            &:after {
                content          : "";
                position         : absolute;
                display          : block;
                transition       : .35s cubic-bezier(.54, 1.60, .5, 1);
                background-color : oklch(var(--totalform-background));
                height           : 28px;
                width            : 28px;
                top              : 0;
                left             : 0;
                border-radius    : 60px;
                box-shadow       : 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
            }
        }

        // When Active
        &:checked {
            & + label:before {
                background-color: oklch(var(--totalform-accent));
                transition: width .2s cubic-bezier(0, 0, 0, .1);
            }

            & + label:after {
                left: 54px - 30px;
            }
        }
    }
}