fieldset.deck-box {

    input, textarea, .fr-box {
        background-color:transparent!important;
        border-style: solid;
        border-color: transparent!important;
        border-width: 1px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0)!important;
        transition:all 200ms ease-in;
        resize:none;
    }
    .fr-toolbar {
        transition:opacity 200ms ease-in;
        opacity: 0;
    }
    fieldset.text-box.textarea {
        border:none!important;
        box-shadow:none!important;
    }
    button {
        background-color:transparent;
        margin:0;
        padding:0;
    }
    .card header {
        margin-bottom:0.5rem;
        position: relative;
    }
    .counter {
        margin:0;
        text-align:center;
        font-weight:normal;
    }
    .thedeck {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        align-items: center;
        padding: 1rem 0;
    }
    .plus {
        flex: 0 0 auto;
        width: 50px;
        cursor: pointer;

        svg {
            width: 80%;
            margin: 0 auto;
            display: block;
            fill: blue;
        }
    }
    .card {
        flex: 0 0 auto;
        width: 100%;
        max-width: 200px;
        margin: 10px;
        border-radius: 10px;
        padding: 15px;
        background-color: #e6e6e6;
        position:relative;
        overflow: visible;

        &:hover .move-card {
            display:block;
        }
        &:hover .counter {
            visibility:hidden;
        }
        &.new, &:hover {
            input, textarea, .fr-box {
                background-color:#fff!important;
                border-style: solid;
                border-width: 1px;
                border-color: #ccc!important;
                box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1)!important;
                resize:vertical;
            }
            .fr-toolbar {
                opacity:1;
            }
            .delete {
                display:block;
            }
        }
        &:not(.new):not(:hover) {
            input, textarea {
                background-image:none!important;
            }
        }
    }
    .move-card {
        height:auto;
        position:absolute;
        top:0;
        left:0;
        width:100%;
        display:none;
        cursor:grab;

        svg {
            margin:0 auto;
            max-width:20px;
        }
        &:active {
            cursor:grabbing;
        }
    }
    .delete {
        cursor:pointer;
        position:absolute;
        top:-10px;
        right:-10px;
        display:none;

        svg {
            width:30px;
            height:30px;
            background:#fff;
            fill:#000;
            border-radius:50%;
        }
    }
}