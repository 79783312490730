//--------------------------------
// Fieldset - Image Field
//--------------------------------
dialog.cms-modal {
	border        : none;
	box-shadow    : rgb(0 0 0 / 0.3) 2px 2px 5px 0px;
	border-radius : var(--totalform-radius);
	padding       : 1.25rem;
	z-index       : 5;
	position      : fixed;
	top           : 50%;
	left          : 50%;
	transform     : translate(-50%, -50%);
	margin        : 0;
	overflow-y    : auto;

	width      : 90%;
	max-width  : 1000px;
	height     : 70vh;
	max-height : 800px;

	&.small {
		max-width : 75ch;
	}

	&[open] {
		display               : grid;
		align-items           : start;
		gap                   : 1rem;
	}

	&.split-view {
		&[open] {
			grid-template-columns : 2fr 3fr;

			@media screen and (max-width: 45em) {
				grid-template-columns : 1fr;
			}
		}
	}

	@media screen and (max-width: 45em) {
		height : 95svh;
		width  : 100%;
	}

	&::backdrop {
		background-color : oklch(var(--totalform-black) / 0.5);
		backdrop-filter  : blur(3px);
	}

	.button {
		cursor : pointer;
		width  : fit-content;
	}

	section {
		position : relative;
		display  : grid;
		gap      : 0.5rem;

		&.scroller {
			max-height    : 100%;
			overflow-y    : auto;
			overflow-x    : hidden;
			padding-right : 0.5rem;

			&::-webkit-scrollbar {
				width: var(--dialog-scrollbar-width);
			}

			&::-webkit-scrollbar-thumb {
				--accent-light: oklch(calc(var(--totalform-accent-l)*1.15) var(--totalform-accent-c) var(--totalform-accent-h));
				border-radius : var(--dialog-scrollbar-width);
				background    : linear-gradient(180deg,var(--accent-light),oklch(var(--totalform-accent)));
				box-shadow    : inset 2px 2px 2px rgba(255,255,255,.25), inset -2px -2px 2px rgba(0,0,0,.25);
			}

			&::-webkit-scrollbar-track {
				background-color  : oklch(var(--totalform-nearwhite));
				border-radius     : var(--dialog-scrollbar-width);
			}
		}
	}
}
