//--------------------------------
// Fieldset - File Field
//--------------------------------
.file-field {

	display: grid;

	&.center {
		justify-items : center;
	}

	> .form-group {
		width     : fit-content;
		min-width : 200px;
	}

	.dz-preview {
		min-width        : 200px;
		background-color : oklch(var(--totalform-nearwhite));

		&:hover {
			.file-icon {
				opacity: 0.5;
			}
		}
	}

	.total-preview {
		min-height : 96px;
	}

	.not-found {
		display : none;
	}

	p.filename {
		margin     : 0 1rem;
		text-align : center;
		font-size  : 0.85rem;
	}

	.actionbar {
		padding               : 0.5rem 0;
		bottom                : 0;
		display               : grid;
		gap                   : 1rem;
		justify-items         : center;
		align-content         : space-evenly;
		grid-template-columns : repeat(3, 1fr);

		button {
			margin : 0;
		}
	}

	.file-icon {
		height           : 64px;
		width            : 64px;
		margin           : 0.5rem auto;
		background-color : oklch(var(--totalform-darkgray));
		mask-position    : bottom center;
		mask-image       : var(--icon-file);
		mask-repeat      : no-repeat;
		mask-size        : 64px;
		transition       : opacity 100ms;
	}
}

.file-icon {
	&.icon-jpeg,
	&.icon-jpg,
	&.icon-png,
	&.icon-gif,
	&.icon-svg,
	&.icon-webp,
	&.icon-ico,
	&.icon-eps,
	&.icon-psd,
	&.icon-ai,
	&.icon-afphoto,
	&.icon-afdesign,
	&.icon-tiff,
	&.icon-bmp,
	&.icon-heic,
	&.icon-heif,
	&.icon-avif,
	&.icon-raw {
		--icon-file : var(--icon-file-image);
	}

	&.icon-txt,
	&.icon-md,
	&.icon-markdown,
	&.icon-mobi,
	&.icon-epub {
		--icon-file : var(--icon-file-text);
	}

	&.icon-pdf,
	&.icon-doc,
	&.icon-docx,
	&.icon-odt,
	&.icon-rtf,
	&.icon-pages,
	&.icon-afpub {
		--icon-file : var(--icon-file-doc);
	}

	&.icon-ppt,
	&.icon-pptx,
	&.icon-key,
	&.icon-odp {
		--icon-file : var(--icon-file-star);
	}

	&.icon-zip,
	&.icon-rar,
	&.icon-7z,
	&.icon-tar,
	&.icon-gz,
	&.icon-tgz,
	&.icon-xz,
	&.icon-bz2,
	&.icon-dmg,
	&.icon-pkg,
	&.icon-iso {
		--icon-file : var(--icon-file-zip);
	}

	&.icon-mp3,
	&.icon-wav,
	&.icon-aiff,
	&.icon-alac,
	&.icon-flac,
	&.icon-m4a,
	&.icon-ogg,
	&.icon-wma,
	&.icon-aac,
	&.icon-mid,
	&.icon-midi {
		--icon-file : var(--icon-file-audio);
	}

	&.icon-m4v,
	&.icon-mp4,
	&.icon-mov,
	&.icon-avi,
	&.icon-wmv,
	&.icon-ogv,
	&.icon-mkv,
	&.icon-flv,
	&.icon-mpg,
	&.icon-webm,
	&.icon-mpeg {
		--icon-file : var(--icon-file-video);
	}

	&.icon-html,
	&.icon-css,
	&.icon-js,
	&.icon-php,
	&.icon-py,
	&.icon-xml,
	&.icon-json,
	&.icon-yaml {
		--icon-file : var(--icon-file-code);
	}

	&.icon-xls,
	&.icon-xlsx,
	&.icon-csv,
	&.icon-ods,
	&.icon-numbers {
		--icon-file : var(--icon-file-chart);
	}
}