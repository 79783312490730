//--------------------------------
// Fieldset - Gallery Field
//--------------------------------

.gallery-field {
	&> .form-group {
		display  : block;
		overflow : hidden;
	}
	.image-preview {
		background-image : var(--opacity-grid);
		background-size  : 20%;
	}
	.total-preview {
		display               : grid;
		gap                   : 0.8rem;
		grid-template-columns : repeat(auto-fill, minmax(200px, 1fr));
		align-items           : center;
		justify-items         : center;
		box-shadow            : none;
		transition            : none;
		min-height            : 96px;
		padding               : 3px 3px 10px 3px;
	}
	.dz-preview {
		width            : fit-content;

		img {
			max-height : 200px;
			min-height : 125px;
			object-fit : cover;
		}
	}
	.actionbar {
		padding             : 0.5rem 0;
		bottom              : 0;
		display             : grid;
		gap                 : 1rem 0;
		justify-items       : center;
		align-content       : start;
		grid-template-rows  : auto auto 1fr;
		grid-template-areas :
			"edit      links    featured"
			"download  clear    trash"
			"move      move     move";

		button {
			margin : 0;
		}
		.edit {
			grid-area : edit;
		}
		.links {
			grid-area : links;
		}
		.featured {
			grid-area : featured;
		}
		.download {
			grid-area : download;
		}
		.move {
			grid-area  : move;
			align-self : end;
		}
		.upload {
			display   : none;
			grid-area : upload;
		}
		.clear {
			grid-area : clear;
		}
		.trash {
			grid-area : trash;
		}
	}
	.gallery-upload {
		display          : none;
		position         : absolute;
		bottom           : 10px;
		right            : 10px;
		background-color : oklch(var(--totalform-white));
		padding          : 1rem;
		aspect-ratio     : 1;
		border-radius    : 50%;
		z-index          : 99;
		transition       : scale 300ms ease-in-out, opacity 300ms linear;
		opacity          : 0.8;
		box-shadow       : 0 0 10px 0 oklch(var(--totalform-shadow));

		button {
			appearance       : none;
			border           : none;
			display          : block;
			padding          : 0;
			margin           : 0;
			cursor           : pointer;
			background-color : oklch(var(--totalform-help-color));
			width            : 1.5rem;
			aspect-ratio     : 1;
			mask-image       : var(--icon-upload-alt);

		}
		&:hover {
			opacity : 0.95;
			scale   : 1.15;
		}
		.dz-drag-hover:not(.sorting) & {
			opacity : 0;
		}
	}
	// Empty Gallery
	&:not(.dz-drag-hover):has(.image-preview) {
		.dz-overlay {
			opacity : 0;
		}
		.gallery-upload {
			display : block;
		}
	}
	.sorting {
		.image-preview {
			opacity : 1;
		}
		.sortable-ghost {
			opacity : 0.5;
		}
		.actionbar {
			display : none;
		}
	}
	&:has(.sorting) {
		.dz-overlay {
			display : none;
		}
	}
	// change border color?
	// .sortable-chosen {
	// }
}