@import "styledtext-froala";

.styledtext-field {
	.fr-view {
		p, ul, ol, blockquote, h1, h2, h3, h4, h5, h6 {
			margin-top: 0;
			margin-bottom: 1rem;
		}
	}
}
