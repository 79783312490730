.totalform,
.simple-form,
.admin-dashboard,
.admin-layout,
.admin-list,
.scrollable,
.cms-colors,
.cms-pagination,
#totalcms-status-banner {
	--icon-help         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%0A %3Cg fill='%23999999'%3E%0A %3Cpath d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'%3E%3C%2Fpath%3E%0A %3C%2Fg%3E%0A%3C%2Fsvg%3E");
	--icon-text         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cline x1='11' y1='30' x2='21' y2='30' stroke='%23252B2D'%3E%3C/line%3E%3Cline x1='16' y1='2' x2='16' y2='30' stroke='%23252B2D'%3E%3C/line%3E%3Cpolyline points='29 6 27 2 5 2 3 6'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-date         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cline x1='5' y1='20' x2='27' y2='20' stroke='%23252B2D'%3E%3C/line%3E%3Cline x1='12' y1='15' x2='12' y2='25' stroke='%23252B2D'%3E%3C/line%3E%3Cline x1='20' y1='15' x2='20' y2='25' stroke='%23252B2D'%3E%3C/line%3E%3Cline x1='9' y1='1' x2='9' y2='5'%3E%3C/line%3E%3Cline x1='23' y1='1' x2='23' y2='5'%3E%3C/line%3E%3Cline x1='31' y1='11' x2='1' y2='11'%3E%3C/line%3E%3Crect x='1' y='5' width='30' height='24' rx='3' ry='3'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-time         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline fill='none' stroke='%23252B2D' stroke-width='2' x1='16' y1='1' x2='16' y2='6' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='%23252B2D' stroke-width='2' x1='31' y1='16' x2='26' y2='16' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='%23252B2D' stroke-width='2' x1='16' y1='31' x2='16' y2='26' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='%23252B2D' stroke-width='2' x1='1' y1='16' x2='6' y2='16' stroke-linecap='butt'%3E%3C/line%3E %3Ccircle fill='none' stroke='%23252B2D' stroke-width='2' cx='16' cy='16' r='15'%3E%3C/circle%3E %3Cpolyline fill='none' stroke='%23252B2D' stroke-width='2' points=' 10,8 16,16 22,16 '%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-email        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cellipse cx='15' cy='15.5' rx='6' ry='6.5' stroke='%23252B2D'%3E%3C/ellipse%3E%3Cpath d='M21,9v11c0,3.215,5.522,3.769,8.08-.578,2.168-3.679,1.636-9.289-1.072-12.981C24.024,1.006,14.811-1.026,8.119,3.466,1.969,7.595-.221,15.948,3.177,22.7c3.363,6.681,11.255,9.919,18.4,7.502'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-lock         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpath d='M10,10.782V7a6.018,6.018,0,0,1,6-6h0a6.018,6.018,0,0,1,6,6v3.783'%3E%3C/path%3E %3Ccircle cx='16' cy='20' r='11'%3E%3C/circle%3E %3Cline x1='16' y1='22' x2='16' y2='25' stroke='%23252B2D'%3E%3C/line%3E %3Ccircle cx='16' cy='19' r='3' stroke='%23252B2D'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-id           : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpolyline points='2 8 2 2 8 2' stroke='%23252B2D'%3E%3C/polyline%3E%3Cpolyline points='30 8 30 2 24 2' stroke='%23252B2D'%3E%3C/polyline%3E%3Cpolyline points='2 24 2 30 8 30' stroke='%23252B2D'%3E%3C/polyline%3E%3Cpolyline points='30 24 30 30 24 30' stroke='%23252B2D'%3E%3C/polyline%3E%3Cpath d='M7,26l4.632-1.544A2,2,0,0,0,13,22.558V21.243C10.187,19.678,9,15.97,9,13V12a7,7,0,0,1,14,0v1c0,2.97-1.187,6.678-4,8.243v1.315a2,2,0,0,0,1.368,1.9L25,26'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-url          : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpath d='M16,20.644A6.968,6.968,0,0,1,13.1,18.9h0a7,7,0,0,1,0-9.9l4.95-4.95a7,7,0,0,1,9.9,0h0a7,7,0,0,1,0,9.9L24.9,17'%3E%3C/path%3E %3Cpath d='M16,11.356A6.968,6.968,0,0,1,18.9,13.1h0a7,7,0,0,1,0,9.9l-4.95,4.95a7,7,0,0,1-9.9,0h0a7,7,0,0,1,0-9.9L7.1,15' stroke='%23252B2D'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-tel          : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpath d='M20.693,19.293l-2.282,2.853a24.077,24.077,0,0,1-8.557-8.557l2.853-2.282a1.939,1.939,0,0,0,.56-2.3l-2.6-5.855A1.939,1.939,0,0,0,8.407,2.062L3.457,3.346A1.95,1.95,0,0,0,2.018,5.5,28.761,28.761,0,0,0,26.5,29.982a1.95,1.95,0,0,0,2.153-1.439l1.284-4.95a1.939,1.939,0,0,0-1.089-2.258l-5.855-2.6A1.939,1.939,0,0,0,20.693,19.293Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-number       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cline x1='9' y1='30' x2='13' y2='2'%3E%3C/line%3E%3Cline x1='19' y1='30' x2='23' y2='2'%3E%3C/line%3E%3Cline x1='6' y1='11' x2='27' y2='11'%3E%3C/line%3E%3Cline x1='5' y1='21' x2='26' y2='21'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-list         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpath d='M19.243,30.414l8.171-8.171a2,2,0,0,0,0-2.829L13,5H2V16L16.414,30.414A2,2,0,0,0,19.243,30.414Z'%3E%3C/path%3E %3Cpolyline points='30 16 15 1 4 1' stroke='%23252B2D'%3E%3C/polyline%3E %3Ccircle cx='9' cy='12' r='3' stroke='%23252B2D'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-slider       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cline x1='17' y1='5' x2='31' y2='5'%3E%3C/line%3E %3Cline x1='1' y1='5' x2='5' y2='5'%3E%3C/line%3E %3Cline x1='28' y1='16' x2='31' y2='16' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='1' y1='16' x2='16' y2='16' stroke='%23252B2D'%3E%3C/line%3E %3Ccircle cx='9' cy='5' r='4'%3E%3C/circle%3E %3Cline x1='17' y1='27' x2='31' y2='27'%3E%3C/line%3E %3Cline x1='1' y1='27' x2='5' y2='27'%3E%3C/line%3E %3Ccircle cx='9' cy='27' r='4'%3E%3C/circle%3E %3Ccircle cx='20' cy='16' r='4' stroke='%23252B2D'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-styledtext   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpolyline points='16,7 6,11 3,29 21,26 25,16 '%3E%3C/polyline%3E %3Cline x1='3' y1='29' x2='11.6' y2='20.4'%3E%3C/line%3E %3Crect x='12.8' y='7.9' transform='matrix(-0.7071 -0.7071 0.7071 -0.7071 30.4853 32.6274)' width='18.4' height='4.2' stroke='%23252B2D'%3E%3C/rect%3E %3Ccircle cx='13' cy='19' r='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-svg          : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cline x1='2' y1='11' x2='30' y2='11' stroke='%23252B2D'%3E%3C/line%3E %3Cpolyline points='16,29 21,11 16,3 ' stroke='%23252B2D'%3E%3C/polyline%3E %3Cpolyline points='16,3 11,11 16,29 ' stroke='%23252B2D'%3E%3C/polyline%3E %3Cline x1='7' y1='3' x2='11' y2='11' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='25' y1='3' x2='21' y2='11' stroke='%23252B2D'%3E%3C/line%3E %3Cpolygon points='30,11 16,29 2,11 7,3 25,3 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-select       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpolyline points=' 1,4 1,1 4,1 ' stroke='%23252B2D'%3E%3C/polyline%3E %3Cpolyline points=' 1,25 1,28 4,28 ' stroke='%23252B2D'%3E%3C/polyline%3E %3Cpolyline points=' 28,4 28,1 25,1 ' stroke='%23252B2D'%3E%3C/polyline%3E %3Cline x1='9' y1='1' x2='12' y2='1' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='17' y1='1' x2='20' y2='1' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='1' y1='9' x2='1' y2='12' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='1' y1='17' x2='1' y2='20' stroke='%23252B2D'%3E%3C/line%3E %3Cpolygon points='28,18 14,14 18,28 21,24 27,30 30,27 24,21 '%3E%3C/polygon%3E %3Cline x1='9' y1='28' x2='12' y2='28' stroke='%23252B2D'%3E%3C/line%3E %3Cline x1='28' y1='9' x2='28' y2='12' stroke='%23252B2D'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-select-multi : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg stroke-linecap='round' stroke-linejoin='round' stroke-width='2' fill='none' stroke='%23252B2D'%3E%3Cpolyline points='10,18 1,18 1,1 31,1 31,18 26,18 '%3E%3C/polyline%3E %3Cline x1='17' y1='18' x2='26' y2='27' stroke='%23252B2D'%3E%3C/line%3E %3Cpolygon points=' 7,8 13,26 17,18 25,14 ' stroke='%23252B2D'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-markdown     : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg stroke-width='1' fill='none' stroke='%23252B2D' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='.5 11.5 .5 4.5 4 8.5 7.5 4.5 7.5 11.5'%3E%3C/polyline%3E%3Cpolyline points='15.5 8.5 13 11 10.5 8.5' stroke='%23252B2D'%3E%3C/polyline%3E%3Cline x1='13' y1='11' x2='13' y2='5.5' stroke='%23252B2D'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-hidden       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Ccircle cx='16' cy='14' r='3' fill='black' stroke='none'%3E%3C/circle%3E %3Cpath d='M1.409,17.182a1.936,1.936,0,0,1-.008-2.37C3.422,12.162,8.886,6,16,6c7.02,0,12.536,6.158,14.585,8.81a1.937,1.937,0,0,1,0,2.38C28.536,19.842,23.02,26,16,26S3.453,19.828,1.409,17.182Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E %3Ccircle cx='16' cy='14' r='8' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E %3Ccircle cx='16' cy='14' r='3' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E %3Cline x1='2' y1='28' x2='28' y2='2' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-checkbox     : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath fill='black' d='M30,5c-7.25,3.125-13.997,8.56-19,14l-7-5l-3,3l10,11c4.562-8.062,10.75-16.125,20-21L30,5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-toggle       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='1' y='7' width='30' height='18' rx='9' ry='9' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E %3Ccircle cx='10' cy='16' r='9' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-range        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline fill='none' stroke='black' stroke-width='2' x1='2' y1='17' x2='11' y2='17' stroke-linecap='butt'%3E%3C/line%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='7,22 2,17 7,12 '%3E%3C/polyline%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='30' y1='17' x2='21' y2='17' stroke-linecap='butt'%3E%3C/line%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='25,22 30,17 25,12 '%3E%3C/polyline%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='17' r='5'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-image        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolygon points='6 25 10 18 14 22 20 14 26 25 6 25' fill='none' stroke='black' stroke-width='2'%3E%3C/polygon%3E%3Ccircle cx='12.5' cy='10.5' r='2.5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Crect x='2' y='2' width='28' height='28' rx='3' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-gallery      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='1' y='6' width='30' height='24' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E %3Cline x1='7' y1='2' x2='25' y2='2' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E %3Cpolygon points='8 25 20 14 27 25 8 25' fill='none' stroke='black' stroke-width='2'%3E%3C/polygon%3E %3Ccircle cx='8.5' cy='13.5' r='2.5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-file         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M13,8V22.5A3.5,3.5,0,0,0,16.5,26h0A3.5,3.5,0,0,0,20,22.5V7a6,6,0,0,0-6-6h0A6,6,0,0,0,8,7V22.5A8.5,8.5,0,0,0,16.5,31h0A8.5,8.5,0,0,0,25,22.5V9' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-depot        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline x1='16' y1='16' x2='16' y2='30' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/line%3E%3Cpolyline points='11 21 16 16 21 21' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cpath d='M11,29H4a3,3,0,0,1-3-3V2H12l3,5H31V26a3,3,0,0,1-3,3H21' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-radio        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Ccircle cx='16' cy='16' r='15' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Ccircle cx='16' cy='16' r='6' fill='black' stroke='none'%3E%3C/circle%3E%3Ccircle cx='16' cy='16' r='6' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-deck         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline x1='29' y1='28' x2='29' y2='4' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpath d='M22,31H6a3,3,0,0,1-3-3V4A3,3,0,0,1,6,1H22a3,3,0,0,1,3,3V28A3,3,0,0,1,22,31Z' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M18.2,13.789a2.748,2.748,0,0,0-4.2.416,2.748,2.748,0,0,0-4.2-.416,2.671,2.671,0,0,0,0,3.81L14,21.75,18.2,17.6A2.671,2.671,0,0,0,18.2,13.789Z' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpolyline points='7 9 7 5 11 5' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cpolyline points='21 23 21 27 17 27' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-json 		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M18.8,1.262C23.2,3.25,25.75,7.85,25.75,13.55s-3.25,10.2-7.45,10.2-6.7-4.35-6.7-8.05a8.3,8.3,0,0,1,4.315-7.009' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M13.2,30.738C8.8,28.75,6.25,24.15,6.25,18.45S9.5,8.25,13.7,8.25s6.7,4.35,6.7,8.05a8.3,8.3,0,0,1-4.315,7.009' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Ccircle cx='16' cy='16' r='15' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

	--icon-featured     : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M31.144,11.643,21.3,10.212l-4.4-8.92a1.041,1.041,0,0,0-1.794,0l-4.4,8.92L.856,11.643A1,1,0,0,0,.3,13.348l7.124,6.944L5.744,30.1A1,1,0,0,0,7.195,31.15L16,26.521l8.805,4.629A1,1,0,0,0,26.256,30.1l-1.682-9.8L31.7,13.348a1,1,0,0,0-.555-1.705Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-not-featured : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='16 2.685 20.326 11.451 30 12.857 23 19.68 24.652 29.315 16 24.766 7.348 29.315 9 19.68 2 12.857 11.674 11.451 16 2.685' fill='none' stroke='black' stroke-width='2'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-trash        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M30,4H21.78L20.97.757A1,1,0,0,0,20,0H12a1,1,0,0,0-.97.757L10.22,4H2A1,1,0,0,0,1,5V7A1,1,0,0,0,2,8H30a1,1,0,0,0,1-1V5A1,1,0,0,0,30,4Z' fill='black'%3E%3C/path%3E%3Cpath d='M5,10V28a4,4,0,0,0,4,4H23a4,4,0,0,0,4-4V10Zm7,15a1,1,0,0,1-2,0V17a1,1,0,0,1,2,0Zm5,0a1,1,0,0,1-2,0V17a1,1,0,0,1,2,0Zm5,0a1,1,0,0,1-2,0V17a1,1,0,0,1,2,0Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-clean        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M27.618,1.215a1,1,0,0,0-1.4.166L17.58,12.328A3.967,3.967,0,0,0,16,12a16.945,16.945,0,0,0-3.973.473c-.016.174-.027.349-.027.527a5.993,5.993,0,0,0,7.161,5.885c.142-.406.293-.81.474-1.212A5.922,5.922,0,0,0,20,16a3.963,3.963,0,0,0-.849-2.435L27.784,2.619A1,1,0,0,0,27.618,1.215Z' fill='black'%3E%3C/path%3E%3Cpath d='M23,21a1,1,0,0,1,0-2h8a1,1,0,0,1,0,2Z' fill='black'%3E%3C/path%3E%3Cpath d='M22,26a1,1,0,0,1,0-2h9a1,1,0,0,1,0,2Z' fill='black'%3E%3C/path%3E%3Cpath d='M31,31H24a1,1,0,1,1,0-2h7a1,1,0,0,1,0,2Z' fill='black'%3E%3C/path%3E%3Cpath d='M20.252,30.5a13.992,13.992,0,0,1-1.888-7,13.821,13.821,0,0,1,.248-2.531c-.2.015-.406.031-.612.031a8.006,8.006,0,0,1-7.994-7.887C3.784,15.581,0,22.055,0,31a1,1,0,0,0,1,1H5.059A22.614,22.614,0,0,1,5.3,26.832a1,1,0,1,1,1.972.334A20.68,20.68,0,0,0,7.063,32h5.37a19.019,19.019,0,0,1-.4-5.062A1.016,1.016,0,0,1,13.092,26a1,1,0,0,1,.937,1.06A17.165,17.165,0,0,0,14.485,32h4.9a1,1,0,0,0,.865-1.5Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-clean-o      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline x1='26.999' y1='2' x2='17.827' y2='13.629' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpath d='M16,13a3,3,0,0,1,3,3,5.468,5.468,0,0,1-.307,1.336A14.934,14.934,0,0,0,19.387,31H1C1,21.059,6.059,13,16,13Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Cline x1='23' y1='20' x2='31' y2='19.999' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cline x1='22' y1='25' x2='31' y2='24.999' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cline x1='23.999' y1='30' x2='31' y2='30' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpath d='M12.276,13.429a4.924,4.924,0,0,0,5.539,6.489' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M7.28,25a22.034,22.034,0,0,0-.151,6' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M12.6,25a18.446,18.446,0,0,0,.661,6' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-link         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M17.619,10.138l-2.241,2.24c-.06.061-.1.13-.158.193a4.958,4.958,0,0,1,2.816,1.393,5.008,5.008,0,0,1,0,7.072l-5.5,5.5a5,5,0,0,1-7.072-7.072l2.385-2.385a10.054,10.054,0,0,1-.23-4.011L3.343,17.343A8,8,0,0,0,14.657,28.657l5.5-5.5a7.99,7.99,0,0,0-2.538-13.019Z' fill='black'%3E%3C/path%3E %3Cpath d='M17.343,3.343l-5.5,5.5a7.99,7.99,0,0,0,2.538,13.019l2.241-2.24c.06-.061.107-.129.162-.193a4.953,4.953,0,0,1-2.82-1.393,5.008,5.008,0,0,1,0-7.072l5.5-5.5a5,5,0,0,1,7.072,7.072l-2.383,2.382a10.086,10.086,0,0,1,.241,4l4.263-4.263A8,8,0,0,0,17.343,3.343Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-upload       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black'%3E%3Cpath d='M17,4H10a5.937,5.937,0,0,0-6,6v7a1,1,0,0,0,2,0V10a3.957,3.957,0,0,1,4-4h7a1,1,0,0,0,0-2Z' fill='black'%3E%3C/path%3E%3Cpath d='M54,4H47a1,1,0,0,0,0,2h7a3.957,3.957,0,0,1,4,4v7a1,1,0,0,0,2,0V10A5.937,5.937,0,0,0,54,4Z' fill='black'%3E%3C/path%3E%3Cpath d='M59,46a1,1,0,0,0-1,1v7a3.957,3.957,0,0,1-4,4H47a1,1,0,0,0,0,2h7a5.937,5.937,0,0,0,6-6V47A1,1,0,0,0,59,46Z' fill='black'%3E%3C/path%3E%3Cpath d='M17,58H10a3.957,3.957,0,0,1-4-4V47a1,1,0,0,0-2,0v7a5.937,5.937,0,0,0,6,6h7a1,1,0,0,0,0-2Z' fill='black'%3E%3C/path%3E%3Cpath d='M25,6H39a1,1,0,0,0,0-2H25a1,1,0,0,0,0,2Z' fill='black'%3E%3C/path%3E%3Cpath d='M39,58H25a1,1,0,0,0,0,2H39a1,1,0,0,0,0-2Z' fill='black'%3E%3C/path%3E%3Cpath d='M59,24a1,1,0,0,0-1,1V39a1,1,0,0,0,2,0V25A1,1,0,0,0,59,24Z' fill='black'%3E%3C/path%3E%3Cpath d='M5,40a1,1,0,0,0,1-1V25a1,1,0,0,0-2,0V39A1,1,0,0,0,5,40Z' fill='black'%3E%3C/path%3E%3Cpath d='M32.781,15.375a1.036,1.036,0,0,0-1.562,0l-12,15A1,1,0,0,0,20,32h9V46a3,3,0,0,0,6,0V32h9a1,1,0,0,0,.781-1.625Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-upload-alt   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M11,10h4V23a1,1,0,0,0,2,0V10h4a1,1,0,0,0,.813-1.581l-5-7a1.035,1.035,0,0,0-1.626,0l-5,7A1,1,0,0,0,11,10Z' fill='black'%3E%3C/path%3E%3Cpath d='M30.966,29.742l-4-15A1,1,0,0,0,26,14H20v2h5.231L28.7,29H3.3L6.769,16H12V14H6a1,1,0,0,0-.966.742l-4,15A1,1,0,0,0,2,31H30a1,1,0,0,0,.966-1.258Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-download     : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M10.11,16.542a1,1,0,0,0,.077,1.039l5,7a1.035,1.035,0,0,0,1.626,0l5-7A1,1,0,0,0,21,16H17V3a1,1,0,0,0-2,0V16H11A1,1,0,0,0,10.11,16.542Z' fill='black'%3E%3C/path%3E%3Cpath d='M27,11H20v2h6.161l2.647,15H3.192L5.839,13H12V11H5a1,1,0,0,0-.985.826l-3,17A1,1,0,0,0,2,30H30a1,1,0,0,0,.985-1.174l-3-17A1,1,0,0,0,27,11Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-edit         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M30.711,1.297c-.188-.189-.444-.297-.711-.297-.356,0-8.774,.061-14.007,5.293-.002,.002-.004,.004-.006,.006-2.97,3.023-4.133,7.345-3.125,11.425l-3.569,3.569c-.391,.391-.391,1.023,0,1.414,.195,.195,.451,.293,.707,.293s.512-.098,.707-.293l3.514-3.514,5.846-5.846-3.366,6.205c.176,.008,.352,.035,.528,.035,3.148,0,6.209-1.255,8.486-3.588,5.122-5.227,5.281-13.633,5.286-13.988,.003-.267-.101-.524-.289-.715Z' fill='black'%3E%3C/path%3E%3Cpath d='M29,19c-.552,0-1,.447-1,1v7c0,1.103-.897,2-2,2H5c-1.103,0-2-.897-2-2V6c0-1.103,.897-2,2-2h7c.552,0,1-.447,1-1s-.448-1-1-1H5C2.794,2,1,3.794,1,6V27c0,2.206,1.794,4,4,4H26c2.206,0,4-1.794,4-4v-7c0-.553-.448-1-1-1Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-bars         : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath fill='black' d='M30,15H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,15,30,15z'%3E%3C/path%3E %3Cpath fill='black' d='M30,6H2C1.4,6,1,6.4,1,7s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,6,30,6z'%3E%3C/path%3E %3Cpath fill='black' d='M30,24H2c-0.6,0-1,0.4-1,1s0.4,1,1,1h28c0.6,0,1-0.4,1-1S30.6,24,30,24z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

	--icon-copyright	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='16' cy='16' r='15' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Cpath d='M19.667,8.76A7.484,7.484,0,0,0,16.238,8C10.524,8,9,12.19,9,16s1.524,8,7.238,8a7.484,7.484,0,0,0,3.429-.76' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-camera		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M4,29H28a3,3,0,0,0,3-3V10a3,3,0,0,0-3-3H23L20,2H12L9,7H4a3,3,0,0,0-3,3V26A3,3,0,0,0,4,29Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Ccircle cx='16' cy='18' r='7' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-shutter		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline fill='none' stroke='black' stroke-width='2' x1='9.055' y1='16.064' x2='17.546' y2='1.079'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='12.578' y1='22.039' x2='3.851' y2='7.201'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='19.516' y1='21.983' x2='2.305' y2='22.121'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='22.94' y1='15.945' x2='14.454' y2='30.921'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='19.422' y1='9.961' x2='28.149' y2='24.799'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='12.484' y1='10.017' x2='29.695' y2='9.879'%3E%3C/line%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='16' r='15'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

	--icon-gps			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolyline points='7.579 5.815 9.689 8.277 11.431 10.456 11.256 13.45 7.77 14.096 5.314 17 6.459 19.377 7.565 21.359 10.172 23.378 8.464 25.97 6.172 26.971' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/polyline%3E%3Cpolyline points='15.461 31 16.6 28.442 15.582 24.892 17.044 21.049 21.26 20.265 24.824 21.773 27.427 25.091' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/polyline%3E%3Cpath d='M28.653,11A14,14,0,1,1,16,3c.329,0,.656.011.979.034' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Ccircle cx='21' cy='6' r='5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Cline x1='21' y1='11' x2='21' y2='16' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-user			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='9' y='2' width='14' height='18' rx='7' ry='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Cpath d='M24.5,20.5l3.458,1.153c.649,.216,1.142,.749,1.308,1.412l.802,3.207c.473,1.893-.959,3.728-2.91,3.728H16s-11.158,0-11.158,0c-1.952,0-3.384-1.834-2.91-3.728l.802-3.207c.166-.663,.659-1.196,1.308-1.412l3.458-1.153' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-duplicate	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M11.5,26.8A10.9,10.9,0,1,0,26.8,11.5' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Ccircle cx='11.909' cy='11.909' r='10.909' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

	--icon-crosshairs	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='16' r='13'%3E%3C/circle%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='16' y1='1' x2='16' y2='6'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='31' y1='16' x2='26' y2='16'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='16' y1='31' x2='16' y2='26'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='16' x2='6' y2='16'%3E%3C/line%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='16' r='3'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

	--icon-width 		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='21 10 27 16 21 22' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E %3Cpolyline points='11 22 5 16 11 10' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E %3Cline x1='1' y1='3' x2='1' y2='29' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E %3Cline x1='31' y1='3' x2='31' y2='29' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-height		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='22 21 16 27 10 21' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E %3Cpolyline points='10 11 16 5 22 11' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E %3Cline x1='29' y1='1' x2='3' y2='1' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E %3Cline x1='29' y1='31' x2='3' y2='31' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-pixels		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='2' y='2' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='10' y='2' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='18' y='2' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='26' y='2' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='2' y='10' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='10' y='10' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='18' y='10' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='26' y='10' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='2' y='18' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='10' y='18' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='18' y='18' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='26' y='18' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='2' y='26' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='10' y='26' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='18' y='26' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E %3Crect x='26' y='26' fill='none' stroke='black' stroke-width='2' width='4' height='4'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-pixelate		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='2' y='9' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='9' y='2' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='2' y='2' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='23' y='2' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='23' y='16' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='2' y='23' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='9' y='16' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Crect x='16' y='23' width='7' height='7' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-quality		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M31,12v13c0,1.657-1.343,3-3,3H4c-1.657,0-3-1.343-3-3V7c0-1.657,1.343-3,3-3h14' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Cpolygon points='8 23 19 12 25 23 8 23' fill='none' stroke='black' stroke-width='2'%3E%3C/polygon%3E%3Ccircle cx='8.5' cy='11.5' r='2.5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Cpath d='M31,7c-2.761,0-5,2.239-5,5,0-2.761-2.239-5-5-5,2.761,0,5-2.239,5-5,0,2.761,2.239,5,5,5Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-imageformat	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='28 4 29 4 29 9' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cpolyline points='28 23 29 23 29 28' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Crect x='27' y='13' width='4' height='6' rx='1.095' ry='1.095' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Cline x1='23' y1='2' x2='23' y2='30' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpath d='M19,28H4c-1.657,0-3-1.343-3-3V7c0-1.657,1.343-3,3-3h15' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Cpolyline points='19 23 8 23 19 12' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Ccircle cx='8.5' cy='11.5' r='2.5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-crop			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline fill='none' stroke='black' stroke-width='2' x1='9' y1='2' x2='9' y2='9'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='23' y1='27' x2='23' y2='30'%3E%3C/line%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='2,9 23,9 23,23 '%3E%3C/polyline%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points=' 9,13 9,23 30,23 '%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-padding		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='7' y='7' fill='none' stroke='black' stroke-width='2' width='18' height='18'%3E%3C/rect%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='7' x2='3' y2='7'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='25' x2='3' y2='25'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='29' y1='7' x2='31' y2='7'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='29' y1='25' x2='31' y2='25'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='7' y1='1' x2='7' y2='3'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='25' y1='1' x2='25' y2='3'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='7' y1='29' x2='7' y2='31'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='25' y1='29' x2='25' y2='31'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-eye 			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M1.409,17.182a1.936,1.936,0,0,1-.008-2.37C3.422,12.162,8.886,6,16,6c7.02,0,12.536,6.158,14.585,8.81a1.937,1.937,0,0,1,0,2.38C28.536,19.842,23.02,26,16,26S3.453,19.828,1.409,17.182Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E %3Ccircle cx='16' cy='16' r='6' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-position		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline x1='22' y1='10' x2='30' y2='2' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpolyline points='22 2 30 2 30 10' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cline x1='10' y1='10' x2='2' y2='2' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpolyline points='10 2 2 2 2 10' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cline x1='22' y1='22' x2='30' y2='30' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpolyline points='22 30 30 30 30 22' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cline x1='10' y1='22' x2='2' y2='30' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpolyline points='10 30 2 30 2 22' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Ccircle cx='16' cy='16' r='5' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-bright		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='16' x2='3' y2='16'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5.4' y1='5.4' x2='6.8' y2='6.8'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='16' y1='1' x2='16' y2='3'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='26.6' y1='5.4' x2='25.2' y2='6.8'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='31' y1='16' x2='29' y2='16'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='26.6' y1='26.6' x2='25.2' y2='25.2'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='16' y1='31' x2='16' y2='29'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5.4' y1='26.6' x2='6.8' y2='25.2'%3E%3C/line%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='16' r='8'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-contrast		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M16,1V31A15,15,0,0,0,16,1Z' fill='black' opacity='0.5' stroke='none'%3E%3C/path%3E%3Cline x1='16' y1='1' x2='16' y2='31' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Ccircle cx='16' cy='16' r='15' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-gamma		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath fill='none' stroke='black' stroke-width='2' d='M10.3,12c0.9-3.5,4-6,7.7-6 c4.4,0,8,3.6,8,8c0,1.3-0.3,2.4-0.8,3.5'%3E%3C/path%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='18' y1='1' x2='18' y2='2'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='27.2' y1='4.8' x2='26.5' y2='5.5'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='31' y1='14' x2='30' y2='14'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='8.8' y1='4.8' x2='9.5' y2='5.5'%3E%3C/line%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M24.2,17.4 c-1.7,0-3.2,0.6-4.4,1.7C18.7,15,15,12,10.5,12C5.3,12,1,16.3,1,21.5c0,5.2,4.3,9.5,9.5,9.5h13.6c3.8,0,6.8-3,6.8-6.8 S27.9,17.4,24.2,17.4z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-sharpen		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolygon points='16 3 16 29 30 29 16 3' fill='black' opacity='0.5' stroke='none'%3E%3C/polygon%3E%3Cline x1='16' y1='3' x2='16' y2='29' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpolygon points='16 3 2 29 30 29 16 3' fill='none' stroke='black' stroke-width='2'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-blur			: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle cx='2' cy='11' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='11' cy='2' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='21' cy='2' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='11' cy='30' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='21' cy='30' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='2' cy='21' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='30' cy='11' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='30' cy='21' r='1' fill='black' stroke='none'%3E%3C/circle%3E %3Ccircle cx='11' cy='11' r='3' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E %3Ccircle cx='21' cy='11' r='3' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E %3Ccircle cx='11' cy='21' r='3' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E %3Ccircle cx='21' cy='21' r='3' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-filter		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='11' r='9'%3E%3C/circle%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='10' cy='21' r='9'%3E%3C/circle%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='22' cy='21' r='9'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-paint-roller	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='2' y='1' fill='none' stroke='black' stroke-width='2' width='28' height='7'%3E%3C/rect%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='4,12 4,15 16,15 16,20 '%3E%3C/polyline%3E %3Crect x='13' y='20' fill='none' stroke='black' stroke-width='2' width='6' height='11'%3E%3C/rect%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M26,18 c0-1.105,2-4,2-4s2,2.895,2,4s-0.895,2-2,2S26,19.105,26,18z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-paint-brush	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath fill='none' stroke='black' stroke-width='2' d='M19.556,26.586l9.899-9.899 l-2.828-2.828l-5.506,1.263l1.263-5.506l-7.071-7.071l-9.899,9.899'%3E%3C/path%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M5.414,12.444L4,13.858 c-1.202,1.202-1.202,3.041,0,4.243l2.828,2.828l-4.95,4.95c-1.202,1.202-1.202,3.041,0,4.243l0,0c1.202,1.202,3.041,1.202,4.243,0 l4.95-4.95L13.899,28c1.202,1.202,3.041,1.202,4.243,0l1.414-1.414L5.414,12.444z'%3E%3C/path%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5.414' y1='26.586' x2='4.707' y2='27.293'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");

	--icon-processing 	: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black'%3E%3Cpath d='M6,32A26,26,0,0,1,47.969,11.486L43.32,15.8a1,1,0,0,0,.455,1.707l15,3.467A.96.96,0,0,0,59,21a1,1,0,0,0,.986-1.169l-2.571-15a1,1,0,0,0-1.666-.564L50.931,8.738A29.99,29.99,0,0,0,2,32a2,2,0,0,0,4,0Z' fill='black'%3E%3C/path%3E%3Cpath d='M60,30a2,2,0,0,0-2,2A26,26,0,0,1,16.031,52.514L20.68,48.2a1,1,0,0,0-.455-1.707l-15-3.467a1,1,0,0,0-1.211,1.143l2.571,15a1,1,0,0,0,.665.778A.987.987,0,0,0,7.571,60a1,1,0,0,0,.68-.267l4.818-4.471A29.99,29.99,0,0,0,62,32,2,2,0,0,0,60,30Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-success		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath fill='black' d='M30,5c-7.25,3.125-13.997,8.56-19,14l-7-5l-3,3l10,11c4.562-8.062,10.75-16.125,20-21L30,5z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-error		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath d='M31.633,26.522,18.683,2.6a3.053,3.053,0,0,0-5.366,0L.367,26.522A3,3,0,0,0,.43,29.514,3.024,3.024,0,0,0,3.05,31h25.9a3.038,3.038,0,0,0,2.683-4.478ZM17.848,11l-.714,10h-2L14.42,11ZM16.134,27a1.778,1.778,0,1,1,1.778-1.778A1.778,1.778,0,0,1,16.134,27Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-blank		: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3C/svg%3E");

	--opacity-grid      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='80' height='80' viewBox='0 0 80 80'%3E%3Cg fill='%23262626' fill-opacity='0.1'%3E%3Cpath fill-rule='evenodd' d='M0 0h40v40H0V0zm40 40h40v40H40V40zm0-40h2l-2 2V0zm0 4l4-4h2l-6 6V4zm0 4l8-8h2L40 10V8zm0 4L52 0h2L40 14v-2zm0 4L56 0h2L40 18v-2zm0 4L60 0h2L40 22v-2zm0 4L64 0h2L40 26v-2zm0 4L68 0h2L40 30v-2zm0 4L72 0h2L40 34v-2zm0 4L76 0h2L40 38v-2zm0 4L80 0v2L42 40h-2zm4 0L80 4v2L46 40h-2zm4 0L80 8v2L50 40h-2zm4 0l28-28v2L54 40h-2zm4 0l24-24v2L58 40h-2zm4 0l20-20v2L62 40h-2zm4 0l16-16v2L66 40h-2zm4 0l12-12v2L70 40h-2zm4 0l8-8v2l-6 6h-2zm4 0l4-4v2l-2 2h-2z'/%3E%3C/g%3E%3C/svg%3E");

	--icon-logo        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M19,13s1.3-7.669-4-11a9.4,9.4,0,0,1-3.525,6.8c-2.264,2-6.52,6.484-6.475,11.28.037,3.875,2.145,8.615,7.011,10.3-.007-.1-.011-.2-.011-.3a7.393,7.393,0,0,1,1.947-4.793,5.939,5.939,0,0,0,1.734-3.717c2.863,1.594,4.319,5.8,4.319,8.3,0,.146,0,.293-.014.439a10.294,10.294,0,0,0,6.974-9.341C27.357,16.2,24.742,12.077,23,9.9' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-schema      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath fill='none' stroke='black' stroke-width='2' d='M3,5v22c0,2.3,6.1,4,13,4s13-1.7,13-4 V5' stroke-linecap='butt'%3E%3C/path%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M29,5 c0,2.3-6.1,4-13,4S3,7.3,3,5s6.1-4,13-4S29,2.7,29,5z'%3E%3C/path%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M29,16c0,2.3-6.1,4-13,4 S3,18.3,3,16'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-collections : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='3' y='9' width='19' height='21' fill='none' stroke='black' stroke-width='2'%3E%3C/rect%3E%3Cpolyline points='11 1 30 1 30 22' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3Cpolyline points='7 5 26 5 26 26' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-warn        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M1.248,27,14.2,3.071a2.051,2.051,0,0,1,3.6,0L30.752,27a2.036,2.036,0,0,1-1.8,3H3.05A2.036,2.036,0,0,1,1.248,27Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Cline x1='16' y1='10' x2='16' y2='20' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Ccircle cx='16' cy='24.5' r='1.5' fill='black' stroke='none'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-settings    : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline x1='34' y1='12' x2='61' y2='12' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='3' y1='12' x2='12' y2='12' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Ccircle cx='20' cy='12' r='8' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3Cline x1='34' y1='52' x2='61' y2='52' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='3' y1='52' x2='12' y2='52' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Ccircle cx='20' cy='52' r='8' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3Cline x1='3' y1='32' x2='30' y2='32' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='52' y1='32' x2='61' y2='32' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Ccircle cx='44' cy='32' r='8' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-docs        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolygon fill='none' stroke='black' stroke-width='2' points='1,2 16,7 31,2 31,25 16,30 1,25 '%3E%3C/polygon%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='13' y1='11.667' x2='5' y2='9'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='13' y1='17.333' x2='5' y2='15'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5' y1='21' x2='13' y2='23.667'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='19' y1='11.667' x2='27' y2='9'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='19' y1='17.333' x2='27' y2='15'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='27' y1='21' x2='19' y2='23.667'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-bookmark    : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M28,30,16,23,4,30V4A3,3,0,0,1,7,1H25a3,3,0,0,1,3,3Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-blog        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath fill='none' stroke='black' stroke-width='2' d='M26,22V1H3v27 c0,1.657,1.343,3,3,3h2'%3E%3C/path%3E %3Cpath fill='none' stroke='black' stroke-width='2' d='M9,28c0,1.657-1.343,3-3,3 h21c1.657,0,3-1.343,3-3v-2H9V28z'%3E%3C/path%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='9' y1='7' x2='20' y2='7'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='9' y1='13' x2='20' y2='13'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='9' y1='19' x2='20' y2='19'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-feed        : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='2' y='4' fill='none' stroke='black' stroke-width='2' width='28' height='8'%3E%3C/rect%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='2' y1='20' x2='30' y2='20'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='2' y1='28' x2='30' y2='28'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-tools       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M16,11.4,13.734,9.331A6.487,6.487,0,0,0,5.366,1.366L9,5,5,9,1.366,5.366a6.49,6.49,0,0,0,7.965,8.368L11.2,15.779' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E %3Cpath d='M18.642,23.943l5.842,6.4a2,2,0,0,0,2.892.066l3.038-3.038a2,2,0,0,0-.066-2.892L23.6,18.325' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E %3Cpath d='M30.634,5.366,27,9,23,5l3.634-3.634a6.49,6.49,0,0,0-8.368,7.965L1.652,24.484a2,2,0,0,0-.066,2.892l3.038,3.038a2,2,0,0,0,2.892-.066L22.669,13.734a6.487,6.487,0,0,0,7.965-8.368Z' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-health      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolyline points='1 16 7 16 12 6 20 26 25 16 31 16' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");

	--icon-save : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23F7F7F7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 18 6 12 18 12 18 18' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/polyline%3E %3Cpath d='M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H17l5,5V20A2,2,0,0,1,20,22Z' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/path%3E %3Cline x1='15' y1='6' x2='15' y2='8' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-trash-o : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cg fill='%23F7F7F7' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M20,9V21a2,2,0,0,1-2,2H6a2,2,0,0,1-2-2V9' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/path%3E %3Cline x1='1' y1='5' x2='23' y2='5' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/line%3E %3Cline x1='12' y1='12' x2='12' y2='18' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/line%3E %3Cline x1='8' y1='12' x2='8' y2='18' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/line%3E %3Cline x1='16' y1='12' x2='16' y2='18' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/line%3E %3Cpolyline points='8 5 8 1 16 1 16 5' fill='none' stroke='%23F7F7F7' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");

	--icon-filter : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='%23676767' stroke-miterlimit='10'%3E%3Cpolygon fill='none' stroke='%23676767' stroke-linecap='round' stroke-linejoin='round' points='0.5,1.5 15.5,1.5 9.5,9.5 9.5,15.5 6.5,15.5 6.5,9.5 '%3E%3C/polygon%3E %3C/g%3E%3C/svg%3E");

	--icon-import  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cline x1='16' y1='20' x2='16' y2='4' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/line%3E%3Cpath d='M22,4h6a3,3,0,0,1,3,3V25a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V7A3,3,0,0,1,4,4h6' fill='none' stroke='black' stroke-width='2'%3E%3C/path%3E%3Cpolyline points='23 13 16 20 9 13' fill='none' stroke='black' stroke-width='2'%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-factory : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath d='M24,18A7,7,0,0,0,24,4c-.047,0-.092.006-.139.007A3.992,3.992,0,0,0,16,5V6a4,4,0,0,0,4,4h1' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M29.447,15.392A4.99,4.99,0,0,1,26,24H25' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M8,18A7,7,0,0,1,8,4c.047,0,.092.006.139.007A3.992,3.992,0,0,1,16,5V6a4,4,0,0,1-4,4H11' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cline x1='16' y1='24' x2='16' y2='19' fill='none' stroke='black' stroke-width='2'%3E%3C/line%3E%3Cpath d='M2.229,22.25A7,7,0,1,0,16,24a7,7,0,1,0,13.771-1.75' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Cpath d='M2.553,15.392A4.99,4.99,0,0,0,6,24H7' fill='none' stroke='black' stroke-width='2' stroke-linecap='butt'%3E%3C/path%3E%3Ccircle cx='9' cy='24' r='2' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Ccircle cx='23' cy='24' r='2' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Ccircle cx='23' cy='10' r='2' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Ccircle cx='16' cy='17' r='2' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3Ccircle cx='9' cy='10' r='2' fill='none' stroke='black' stroke-width='2'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-build   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='9.015' y='6.964' transform='matrix(0.7071 -0.7071 0.7071 0.7071 -2.299 15.4497)' fill='none' stroke='black' stroke-width='2' width='16.971' height='7.071'%3E%3C/rect%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='21.5,11.5 30,20 27,23 18.5,14.5 '%3E%3C/polyline%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='3,31 3,26 19,26 19,31 '%3E%3C/polyline%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='22' x2='4' y2='22'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='3.636' y1='15.636' x2='5.757' y2='17.757'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='1' y1='31' x2='21' y2='31'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-flag    : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolyline fill='none' stroke='black' stroke-width='2' points='5,3 29,3 29,19 5,19 ' stroke-linecap='butt'%3E%3C/polyline%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5' y1='11' x2='29' y2='11' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='13' y1='3' x2='13' y2='19' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='21' y1='3' x2='21' y2='19' stroke-linecap='butt'%3E%3C/line%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='5' y1='1' x2='5' y2='31'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-dots    : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='4' r='3'%3E%3C/circle%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='16' r='3'%3E%3C/circle%3E %3Ccircle fill='none' stroke='black' stroke-width='2' cx='16' cy='28' r='3'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-refresh : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black'%3E%3Cpath fill='black' d='M4,16C4,9.383,9.383,4,16,4c2.377,0,4.657,0.696,6.603,1.983l-3.724,3.724l10.3,1.472l-1.472-10.3 l-3.672,3.672C21.693,2.903,18.911,2,16,2C8.28,2,2,8.28,2,16c0,0.552,0.448,1,1,1S4,16.552,4,16z'%3E%3C/path%3E %3Cpath fill='black' d='M29,15c-0.552,0-1,0.448-1,1c0,6.617-5.383,12-12,12c-2.377,0-4.657-0.696-6.603-1.983l3.724-3.724 l-10.3-1.472l1.472,10.3l3.672-3.672C10.307,29.097,13.089,30,16,30c7.72,0,14-6.28,14-14C30,15.448,29.552,15,29,15z'%3E%3C/path%3E %3C/g%3E%3C/svg%3E");
	--icon-logout  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath fill='none' stroke='black' stroke-width='2' d='M10.5,13c1.3-4.1,5.1-7,9.5-7 c5.5,0,10,4.5,10,10s-4.5,10-10,10c-4.5,0-8.3-2.9-9.5-7' stroke-linecap='butt'%3E%3C/path%3E %3Cline fill='none' stroke='black' stroke-width='2' x1='23' y1='16' x2='2' y2='16' stroke-linecap='butt'%3E%3C/line%3E %3Cpolyline fill='none' stroke='black' stroke-width='2' points='7,21 2,16 7,11 '%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E");
	--icon-profile : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpath fill='none' stroke='black' stroke-width='4' d='M32,42 L32,42c-6.075,0-11-4.925-11-11v-3c0-6.075,4.925-11,11-11h0c6.075,0,11,4.925,11,11v3C43,37.075,38.075,42,32,42z'%3E%3C/path%3E %3Cpath fill='none' stroke='black' stroke-width='4' d='M14.529,56.391 C16.973,51.421,22.088,48,28,48h8c5.906,0,11.024,3.43,13.471,8.391' stroke-linecap='butt'%3E%3C/path%3E %3Ccircle fill='none' stroke='black' stroke-width='4' cx='32' cy='32' r='30'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");

	--icon-file       : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Cpolyline points='56 20 39 20 39 3' fill='none' stroke='black' stroke-width='4' stroke-linecap='butt'%3E%3C/polyline%3E%3Cpolygon points='56 20 56 61 8 61 8 3 39 3 56 20' fill='none' stroke='black' stroke-width='4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-file-text  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cline x1='17' y1='47' x2='31' y2='47' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='17' y1='37' x2='47' y2='37' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='17' y1='27' x2='47' y2='27' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='17' y1='17' x2='47' y2='17' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-file-doc   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cline x1='37' y1='17' x2='46' y2='17' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='37' y1='27' x2='46' y2='27' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='18' y1='37' x2='46' y2='37' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='18' y1='47' x2='46' y2='47' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Crect x='18' y='16' width='12' height='12' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-file-zip   : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cline x1='28' y1='10' x2='36' y2='10' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='28' y1='17' x2='36' y2='17' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='28' y1='24' x2='36' y2='24' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Crect x='25' y='31' width='14' height='14' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-file-audio : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolyline points='25 41 25 19 43 19 43 38' fill='none' stroke='black' stroke-width='4' stroke-linecap='butt'%3E%3C/polyline%3E%3Ccircle cx='21' cy='41' r='4' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3Ccircle cx='39' cy='38' r='4' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3Cline x1='25' y1='25' x2='43' y2='25' fill='none' stroke='black' stroke-width='4' stroke-linecap='butt'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-file-video : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolygon points='24 21 42 32 24 43 24 21' fill='none' stroke='black' stroke-width='4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-file-image : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolygon points='47 46 17 46 37 27 47 46' fill='none' stroke='black' stroke-width='4'%3E%3C/polygon%3E%3Ccircle cx='22.5' cy='22.5' r='4.5' fill='none' stroke='black' stroke-width='4'%3E%3C/circle%3E%3C/g%3E%3C/svg%3E");
	--icon-file-code  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolyline points='23 25 16 32 23 39' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Cpolyline points='41 39 48 32 41 25' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Cline x1='28' y1='44' x2='36' y2='20' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");
	--icon-file-chart : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='square' stroke-linejoin='miter' stroke-miterlimit='10'%3E%3Crect x='29' y='21' width='6' height='22' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Crect x='16' y='35' width='6' height='8' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Crect x='42' y='30' width='6' height='13' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");
	--icon-file-star  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect x='8' y='3' width='48' height='58' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolygon points='32 19.636 36.017 27.776 45 29.081 38.5 35.417 40.034 44.364 32 40.14 23.966 44.364 25.5 35.417 19 29.081 27.983 27.776 32 19.636' fill='none' stroke='black' stroke-width='4'%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");

	--icon-macro : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M54,7h2.5c1.381,0,2.5,1.119,2.5,2.5V27.789c0,.79,.234,1.562,.672,2.219l1.328,1.992-1.328,1.992c-.438,.657-.672,1.429-.672,2.219v18.289c0,1.381-1.119,2.5-2.5,2.5h-2.405' fill='none' stroke='black' stroke-width='4'%3E%3C/path%3E%3Cpath d='M10,7h-2.5c-1.381,0-2.5,1.119-2.5,2.5V27.789c0,.79-.234,1.562-.672,2.219l-1.328,1.992,1.328,1.992c.438,.657,.672,1.429,.672,2.219v18.289c0,1.381,1.119,2.5,2.5,2.5h2.405' fill='none' stroke='black' stroke-width='4'%3E%3C/path%3E%3Cpolyline points='19 7 21 7 21 19' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Cpolyline points='42 7 44 7 44 19' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Crect x='28' y='7' width='8' height='12' rx='2.5' ry='2.5' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolyline points='19 45 21 45 21 57' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Cpolyline points='42 45 44 45 44 57' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Crect x='28' y='45' width='8' height='12' rx='2.5' ry='2.5' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Cpolyline points='31 26 33 26 33 38' fill='none' stroke='black' stroke-width='4'%3E%3C/polyline%3E%3Crect x='17' y='26' width='8' height='12' rx='2.5' ry='2.5' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3Crect x='40' y='26' width='8' height='12' rx='2.5' ry='2.5' fill='none' stroke='black' stroke-width='4'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E");

	--icon-question : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black'%3E%3Cpath d='M34,47h-4c-.552,0-1-.448-1-1v-2c0-7.961,4.9-12.4,9.227-16.316,3.634-3.291,6.773-6.134,6.773-10.684,0-5.383-4.822-9-12-9-5.035-.138-9.875,1.871-13.328,5.496-.382,.401-1.029,.389-1.421-.003l-2.811-2.811c-.381-.381-.401-1.005-.029-1.394,4.57-4.78,10.949-7.435,17.589-7.289,10.6,0,18,6.168,18,15,0,7.211-4.646,11.419-8.746,15.131-3.893,3.525-7.254,6.569-7.254,11.869v2c0,.552-.448,1-1,1Z' fill='black'%3E%3C/path%3E%3Cpath d='M32,62c-2.761,0-5-2.239-5-5s2.239-5,5-5,5,2.239,5,5c-.003,2.76-2.24,4.997-5,5Z' fill='black'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

	--icon-twig-playground : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='black'%3E%3Cpath d='M10,9.331c1.443-.43,2.5-1.748,2.5-3.331v-.5h-1c-1.933,0-3.5,1.567-3.5,3.5v3.5' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M14,15.5H2c-.828,0-1.5-.672-1.5-1.5V4.5L8,.5l7.5,3.999V14c0,.828-.672,1.5-1.5,1.5Z' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3Cpath d='M6,10.331c-1.443-.43-2.5-1.748-2.5-3.331v-.5h1c1.933,0,3.5,1.567,3.5,3.5v3.5' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");

	--icon-folder      : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='black'%3E%3Cpath d='M7.5,4.5l-2-3H.5V13A1.5,1.5,0,0,0,2,14.5H14A1.5,1.5,0,0,0,15.5,13V4.5Z' fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
	--icon-folder-open : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cg fill='black'%3E%3Cpolyline fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' points='12.5,4.5 12.5,2.5 5.5,2.5 4.5,0.5 0.5,0.5 0.5,11.5 '%3E%3C/polyline%3E %3Cpolygon fill='none' stroke='black' stroke-linecap='round' stroke-linejoin='round' points='1.5,14.5 4.5,6.5 15.5,6.5 12.5,14.5 '%3E%3C/polygon%3E%3C/g%3E%3C/svg%3E");
	--icon-folder-add  : url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64'%3E%3Cg fill='black' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M56,58H8a5,5,0,0,1-5-5V6H24l6,10H61V53A5,5,0,0,1,56,58Z' fill='none' stroke='black' stroke-width='4'%3E%3C/path%3E%3Cline x1='32' y1='28' x2='32' y2='46' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3Cline x1='41' y1='37' x2='23' y2='37' fill='none' stroke='black' stroke-width='4'%3E%3C/line%3E%3C/g%3E%3C/svg%3E");

	--totalform-accent-l : 60.45%;
	--totalform-accent-c : 0.107;
	--totalform-accent-h : 183.38;
	// --totalform-accent   : var(--totalform-accent-l) var(--totalform-accent-c) var(--totalform-accent-h);
	--totalform-accent   : var(--totalform-blue);

	--totalform-white      : 100% 0 0;
	--totalform-nearwhite  : 97% 0 0;
	--totalform-lightgray  : 87% 0 0;
	--totalform-mediumgray : 72% 0 0;
	--totalform-gray       : 60% 0 0;
	--totalform-darkgray   : 34% 0 0;
	--totalform-nearblack  : 17% 0 0;
	--totalform-black      : 0% 0 0 ;

	--totalform-blue   : 65% 0.14 253.93;
	--totalform-orange : 78% 0.16 72.98;
	--totalform-green  : 67% 0.14 152.84;
	--totalform-yellow : 91% 0.19 103.96;
	--totalform-red    : 63% 0.22 32.01;

	--totalform-changed : var(--totalform-blue);
	--totalform-success : var(--totalform-green);
	--totalform-error   : var(--totalform-red);
	--totalform-warning : var(--totalform-orange);


	--totalform-border-color : var(--totalform-lightgray);
	--totalform-label-color  : var(--totalform-darkgray);
	--totalform-help-color   : var(--totalform-darkgray);
	--totalform-text-color   : var(--totalform-nearblack);
	--totalform-background   : var(--totalform-white);
	--totalform-icon-bg      : var(--totalform-nearwhite);
	--totalform-icon-color   : var(--totalform-darkgray);
	--totalform-shadow       : 14% 0 0 / 0.1;
	--totalform-placeholder  : var(--totalform-mediumgray);


	--totalform-label-size   : 0.875rem;
	--totalform-help-size    : var(--totalform-label-size);
	--totalform-font-size    : 1rem;


	--totalform-radius       : 5px;
	--help-animation-timing  : 0.25s;
	--dialog-scrollbar-width : 12px;

	--totalform-status-banner-height : 100vh;
}

.actionbar {
	--actionbar-color: var(--totalform-white) / 0.6;
	--actionbar-icon-color: var(--totalform-nearblack);
	--actionbar-icon-size: 24px;
}