//------------------------------
// Slider Field
//------------------------------
input[type="range"] {
	appearance       : none;
	width            : 100%;
	height           : 32px;
	outline          : none;
	overflow         : hidden;
	border           : 1px solid oklch(var(--totalform-border-color));
	border-radius    : 60px;
	background-color : oklch(var(--totalform-background));
	padding          : 0;
	margin           : 0;

    &::-webkit-slider-thumb {
		-webkit-appearance : none;
		width              : 30px;
		height             : 30px;
		border-radius      : 60px;
		margin             : 0;
		background         : oklch(var(--totalform-accent));
		border             : 15px solid oklch(var(--totalform-nearwhite));
		cursor             : pointer;
		box-shadow         : -615px 0 0 600px oklch(var(--totalform-accent)), 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 0px 0 hsla(0, 0%, 0%, .04), 0 4px 9px hsla(0, 0%, 0%, .13), 0 3px 3px hsla(0, 0%, 0%, .05);
    }
	&::-webkit-slider-runnable-track {
		height           : auto;
		background-color : oklch(var(--totalform-background));
	}

}
.range-value {
    padding   : 0.5rem 1rem;
    width     : 4ch;
    font-size : 1.1rem;
}
