.schemaProperties-field,
.customProperties-field,
.properties-field {
	margin-top : 2rem;

	> .form-group {
		display    : grid;
		box-shadow : none !important;
		gap        : 0.5rem;
	}

	.cms-accordion {

		&:has(.form-field.unsaved) > summary {
			border-color: oklch(var(--totalform-changed)) !important;
		}
		&:has(.form-field.error) > summary {
			border-color : oklch(var(--totalform-error)) !important;
		}
	}
}

.customProperties-field {
	>div>.cms-accordion {
		> .content {
			gap : 0.5rem;
		}

		> summary {
			font-size   : 1rem;
			font-weight : normal;
			padding     : 0.5rem 1rem;
		}
	}
	.customProperties-actionbar {
		display               : inline-grid;
		grid-template-columns : auto auto;
		justify-content       : space-between;
		width                 : calc(100% - 1.1rem);

		input {
			width         : fit-content;
			height        : auto;
			display       : inline-block;
			background    : transparent;
			border        : none;
			box-shadow    : none;
			padding       : 0.5rem;
			transition    : background-color 200ms ease;

			&:focus, &:hover {
				background-color : oklch(var(--totalform-lightgray) / 50%);
			}
		}
		.actions {
			display     : flex;
			align-items : center;
			gap         : 0.5rem;
		}
	}
}

.properties-field button:active,
.customProperties-object > summary:active {
	cursor : grabbing;
}

.customProperties-field summary {
	position: relative;

	button {
		appearance  : none;
		border      : none;
		cursor      : pointer;
		margin-left : auto;

		opacity          : 0;
		background-color : oklch(var(--totalform-icon-color));
		width            : 1.5rem;
		height           : 1.5rem;
		transition       : scale 200ms ease-in-out, opacity 200ms ease;

		&:hover {
			scale : 1.1;
        }
		&:active {
			scale : 1;
        }

		&.trash {
			mask-image : var(--icon-trash);
		}
		&.duplicate {
			mask-image : var(--icon-duplicate);
		}
    }
	&:hover button {
		opacity : 1;
	}
}


.property-field,
.schema-field {
	--schema-icon      : var(--icon-question);
	--schema-type-icon : var(--icon-question);

	display               : inline-grid;
	grid-template-columns : max-content auto max-content max-content;
	padding               : 0.5rem 1rem 0.5rem 5rem;
	gap                   : 0;
	border-radius         : var(--totalform-radius);
	background-color      : oklch(var(--totalform-nearwhite));
	position              : relative;
	border                : 1px solid oklch(var(--totalform-nearwhite));
	transition            : border-color 200ms ease-in-out;

	&:hover {
		border-color : oklch(var(--totalform-lightgray));
	}

	&:has(.form-field.unsaved) {
		border-color: oklch(var(--totalform-changed)) !important;
	}
	&:has(.form-field.error) {
		border-color : oklch(var(--totalform-error)) !important;
	}

	&:before, &:after {
		display          : block;
		content          : " ";
		transition       : background-color 200ms ease-in-out;
		width            : 1.1rem;
		height           : 1.1rem;
		background-color : oklch(var(--totalform-icon-color));
		mask-image       : var(--schema-icon);
		mask-repeat      : no-repeat;
		position         : absolute;
		top              : 50%;
	}

	&:before {
		translate : 125% -50%;
	}

	&:after {
		mask-image : var(--schema-type-icon);
		translate  : 275% -50%;
		opacity   : 0.5;
	}

	> input[type="text"] {
		padding          : 0.5rem;
		min-width        : 250px;
		height           : 2rem;
		border           : none;
		border-radius    : var(--totalform-radius);
		background-color : oklch(var(--totalform-nearwhite));
		box-shadow       : none;
		text-indent      : 0.5rem;
		transition       : background-color 200ms ease-in-out;

		&:focus {
			background-color : oklch(var(--totalform-lightgray) / 50%);
		}

	}
	&:hover > input {
		background-color : oklch(var(--totalform-lightgray) / 50%);
	}

	> button {
		appearance       : none;
		cursor           : pointer;
		border           : 0px solid oklch(var(--totalform-icon-color));
		padding          : 0 0.5rem 0 0;
		background-color : oklch(var(--totalform-icon-color));
		mask-image       : var(--icon-settings);
		mask-repeat      : no-repeat;
		mask-position    : center right;
		mask-size        : 1.1rem;
		min-width        : 2rem;
		transition       : mask-size 200ms ease-in-out;
		margin           : 0;

		&:hover,
		&:focus-visible {
			mask-size : 1.2rem;
		}
		&:active {
			mask-size : 1.1rem;
		}

		&.trash {
			mask-image : var(--icon-trash);
		}
		&.duplicate {
			mask-image : var(--icon-duplicate);
		}
	}

	dialog {
		grid-template-rows: auto min-content;
	}
}

.property-field {
	--schema-icon : var(--icon-text);

	&:after {
		display : none;
	}
}

.boolean-type {
	--schema-type-icon : var(--icon-checkbox);
}
.color-type {
	--schema-type-icon : var(--icon-paint-roller);
}
.date-type {
	--schema-type-icon : var(--icon-date);
}
.deck-type {
	--schema-type-icon : var(--icon-deck);
}
.depot-type {
	--schema-type-icon : var(--icon-depot);
}
.email-type {
	--schema-type-icon : var(--icon-email);
}
.file-type {
	--schema-type-icon : var(--icon-file);
}
.gallery-type {
	--schema-type-icon : var(--icon-gallery);
}
.image-type {
	--schema-type-icon : var(--icon-image);
}
.list-type {
	--schema-type-icon : var(--icon-list);
}
.number-type {
	--schema-type-icon : var(--icon-number);
}
.password-type {
	--schema-type-icon : var(--icon-lock);
}
.phone-type {
	--schema-type-icon : var(--icon-tel);
}
.slug-type {
	--schema-type-icon : var(--icon-id);
}
.string-type {
	--schema-type-icon : var(--icon-text);
}
.svg-type {
	--schema-type-icon : var(--icon-svg);
}
.time-type {
	--schema-type-icon : var(--icon-time);
}
.url-type {
	--schema-type-icon : var(--icon-url);
}


.id-field {
	--schema-icon : var(--icon-id);

	&.slug-type:after {
		display : none;
	}
}
.text-field {
	--schema-icon : var(--icon-text);

	&.string-type:after {
		display : none;
	}
}
.time-field {
	--schema-icon : var(--icon-time);

	&.time-type:after {
		display : none;
	}
}
.url-field {
	--schema-icon : var(--icon-url);

	&.url-type:after {
		display : none;
	}
}
.hidden-field {
	--schema-icon : var(--icon-hidden);
}
.email-field {
	--schema-icon : var(--icon-email);

	&.email-type:after {
		display : none;
	}
}
.phone-field {
	--schema-icon : var(--icon-tel);

	&.phone-type:after {
		display : none;
	}
}
.password-field {
	--schema-icon : var(--icon-lock);

	&.password-type:after {
		display : none;
	}
}
.textarea-field {
	--schema-icon : var(--icon-text);

	&.string-type:after {
		display : none;
	}
}
.checkbox-field {
	--schema-icon : var(--icon-checkbox);

	&.boolean-type:after {
		display : none;
	}
}
.toggle-field {
	--schema-icon : var(--icon-toggle);

	&.boolean-type:after {
		display : none;
	}
}
.number-field {
	--schema-icon : var(--icon-number);

	&.number-type:after {
		display : none;
	}
}
.color-field {
	--schema-icon : var(--icon-paint-roller);

	&.color-type:after {
		display : none;
	}
}
.date-field {
	--schema-icon : var(--icon-date);

	&.date-type:after {
		display : none;
	}
}
.datetime-field {
	--schema-icon : var(--icon-date);

	&.date-type:after {
		display : none;
	}
}
.select-field {
	--schema-icon : var(--icon-select);
}
.multiselect-field {
	--schema-icon : var(--icon-select-multi);
}
.list-field {
	--schema-icon : var(--icon-list);

	&.list-type:after {
		display : none;
	}
}
.range-field {
	--schema-icon : var(--icon-range);

	&.number-type:after {
		display : none;
	}
}
.styledtext-field {
	--schema-icon : var(--icon-styledtext);

	&.string-type:after {
		display : none;
	}
}
.svg-field {
	--schema-icon : var(--icon-svg);

	&.svg-type:after {
		display : none;
	}
}
.image-field {
	--schema-icon : var(--icon-image);

	&.image-type:after {
		display : none;
	}
}
.gallery-field {
	--schema-icon : var(--icon-gallery);

	&.gallery-type:after {
		display : none;
	}
}
.file-field {
	--schema-icon : var(--icon-file);

	&.file-type:after {
		display : none;
	}
}
.depot-field {
	--schema-icon : var(--icon-depot);

	&.depot-type:after {
		display : none;
	}
}
.radio-field {
	--schema-icon : var(--icon-radio);
}
.deck-field {
	--schema-icon : var(--icon-deck);

	&.deck-type:after {
		display : none;
	}
}
.json-field {
	--schema-icon : var(--icon-json);
}
