//------------------------------
// Checkbox Field
//------------------------------
.checkbox-field {
	width : auto;

    [type="checkbox"] {
        appearance  : auto;
        width       : 1.2rem;
        height      : 1.2rem;
        display     : inline-block;
        position    : relative;
        margin      : 0 0.2rem 0 0;
        border      : none;
        line-height : 1;
        box-shadow  : none;
        cursor      : pointer;

        + label {
			display     : inline-block;
			font-size   : 1.1rem;
			line-height : 1.2rem;
			cursor      : pointer;
        }

        &:before {
            transition : all .2s ease-in-out;
            content    : "";
            position   : absolute;
            left       : 0;
            top        : 0;
            z-index    : 1;
            width      : 1.2rem;
            height     : 1.2rem;
            border     : 2px solid oklch(var(--totalform-border-color))
        }

        &:checked:before {
            transform          : rotate(-45deg);
            height             : .5rem;
            top                : 20%;
            border-color       : oklch(var(--totalform-accent));
            border-top-style   : none;
            border-right-style : none
        }

        &:after { // hides the actual checkbox
            content    : "";
            position   : absolute;
            top        : -0.1rem;
            left       : -0.1rem;
            width      : 1.4rem;
            height     : 1.4rem;
            background : oklch(var(--totalform-background));
            cursor     : pointer
        }
    }
}
