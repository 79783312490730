//------------------------------
// Text Fields
//------------------------------
::placeholder {
    color: oklch(var(--totalform-placeholder));
}

.totalform {
    [type="text"], [type="email"], [type="date"], [type="datetime-local"], [type="month"],
    [type="number"], [type="password"], [type="tel"], [type="time"], [type="search"],
    [type="url"], [type="week"], select, textarea {
		display          : block;
		box-sizing       : border-box;
		width            : 100%;
		height           : 2.4375rem;
		padding          : .5rem;
		margin           : 0;
		border           : 1px solid oklch(var(--totalform-border-color));
		border-radius    : var(--totalform-radius);
		background-color : oklch(var(--totalform-background));
		font-family      : inherit;
		font-size        : var(--totalform-font-size);
		font-weight      : 400;
		line-height      : 1.5;
		color            : oklch(var(--totalform-text-color));
		appearance       : none;
		box-shadow       : inset 0 1px 2px oklch(var(--totalform-shadow));
		transition       : box-shadow .5s, border-color .25s ease-in-out;
    }

	input, select, textarea {
		&:has(~ .form-group-icon) {
			border-radius : var(--totalform-radius) 0 0 var(--totalform-radius);
		}
	}

    textarea {
        max-width : 100%;
        resize    : vertical;

        &[rows] {
            height     : auto;
            min-height : 3em;
        }
    }

    [readonly] {
        background-color : oklch(var(--totalform-lightgray));
        cursor           : not-allowed
    }

    select {
        + .form-group-icon:after {
            mask-image : var(--icon-select);
        }

        &:not([multiple]):has([selected][disabled])  {
			// There is JS that will remove the selected attribute from the first option on change
            color : oklch(var(--totalform-placeholder));
        }

        &:not([multiple]) {
            background-image    : url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZlcnNpb249JzEuMScgd2lkdGg9JzMyJyBoZWlnaHQ9JzI0JyB2aWV3Qm94PScwIDAgMzIgMjQnPjxwb2x5Z29uIHBvaW50cz0nMCwwIDMyLDAgMTYsMjQnIHN0eWxlPSdmaWxsOiM4YThhOGEnPjwvcG9seWdvbj48L3N2Zz4=);
            background-origin   : content-box;
            background-position : right -1rem center;
            background-repeat   : no-repeat;
            background-size     : 9px 6px;
            padding-right       : 1.5rem;
        }
        &[multiple] {
            height           : auto;
            background-image : none;

            // + .form-group-icon {
            //     background-image: var(--icon-select-multi);
            // }
        }
		&:focus-visible {
			outline : none;
		}
		&[disabled] {
			box-shadow : none;
			opacity    : 1;
		}
    }

	.form-group + .form-group {
		margin-top : 1rem;
	}
}

//------------------------------
// SVG + StyledText Field
//------------------------------
.svg-field .form-group-icon {
	&:after {
		mask-image : var(--icon-svg);
	}
}
.styledtext-field .form-group-icon {
	&:after {
		mask-image : var(--icon-styledtext);
	}
}

//------------------------------
// JSON Field
//------------------------------
.json-field .form-group-icon {
	&:after {
		mask-image : var(--icon-json);
	}
}

//------------------------------
// List Field
//------------------------------
.list-field .form-group-icon {
	&:after {
		mask-image : var(--icon-list);
	}
}

//------------------------------
// Date Field
//------------------------------
[type="date"] + .form-group-icon,
[type="datetime-local"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-date);
	}
}

//------------------------------
// Time Field
//------------------------------
[type="time"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-time);
	}
}

//------------------------------
// Email Field
//------------------------------
[type="email"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-email);
	}
}

//------------------------------
// Password Field
//------------------------------
[type="password"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-lock);
	}
}

//------------------------------
// ID Field
//------------------------------
.form-field.id-field {
    .form-group-icon {
		border-width : 0;

		&:after {
			mask-image : var(--icon-id);
		}
    }

    input[type="text"] {
        background-color : oklch(var(--totalform-nearwhite));
        border           : none;
        box-shadow       : none;

		&[readonly] {
			background-color : oklch(var(--totalform-lightgray));
		}
    }
}

//------------------------------
// URL Field
//------------------------------
[type="url"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-url);
	}
}

//------------------------------
// Telephone Field
//------------------------------
[type="tel"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-tel);
	}
}

//------------------------------
// Number Field
//------------------------------
[type="number"] + .form-group-icon {
	&:after {
		mask-image : var(--icon-number);
	}
}

//------------------------------
// Markdown Field
//------------------------------
.markdown-field .form-group-icon {
	&:after {
		mask-image : var(--icon-markdown);
	}
}

//------------------------------
// Copyright Field
//------------------------------
.form-field.icon-copyright .form-group-icon {
	&:after {
		mask-image : var(--icon-copyright);
	}
}

//------------------------------
// GPS Field
//------------------------------
.form-field.icon-gps .form-group-icon {
	&:after {
		mask-image : var(--icon-gps);
	}
}

//------------------------------
// User Field
//------------------------------
.form-field.icon-user .form-group-icon {
	&:after {
		mask-image : var(--icon-user);
	}
}

//------------------------------
// Camera Field
//------------------------------
.form-field.icon-camera .form-group-icon {
	&:after {
		mask-image : var(--icon-camera);
	}
}
.form-field.icon-shutter .form-group-icon {
	&:after {
		mask-image : var(--icon-shutter);
	}
}

//------------------------------
// More Custom Icons
//------------------------------
.form-field.icon-width .form-group-icon {
	&:after {
		mask-image : var(--icon-width);
	}
}
.form-field.icon-height .form-group-icon {
	&:after {
		mask-image : var(--icon-height);
	}
}
.form-field.icon-pixels .form-group-icon {
	&:after {
		mask-image : var(--icon-pixels);
	}
}
.form-field.icon-pixelate .form-group-icon {
	&:after {
		mask-image : var(--icon-pixelate);
	}
}
.form-field.icon-imageformat .form-group-icon {
	&:after {
		mask-image : var(--icon-imageformat);
	}
}
.form-field.icon-quality .form-group-icon {
	&:after {
		mask-image : var(--icon-quality);
	}
}
.form-field.icon-crop .form-group-icon {
	&:after {
		mask-image : var(--icon-crop);
	}
}
.form-field.icon-padding .form-group-icon {
	&:after {
		mask-image : var(--icon-padding);
	}
}
.form-field.icon-eye .form-group-icon {
	&:after {
		mask-image : var(--icon-eye);
	}
}
.form-field.icon-position .form-group-icon {
	&:after {
		mask-image : var(--icon-position);
	}
}
.form-field.icon-bright .form-group-icon {
	&:after {
		mask-image : var(--icon-bright);
	}
}
.form-field.icon-contrast .form-group-icon {
	&:after {
		mask-image : var(--icon-contrast);
	}
}
.form-field.icon-gamma .form-group-icon {
	&:after {
		mask-image : var(--icon-gamma);
	}
}
.form-field.icon-sharpen .form-group-icon {
	&:after {
		mask-image : var(--icon-sharpen);
	}
}
.form-field.icon-blur .form-group-icon {
	&:after {
		mask-image : var(--icon-blur);
	}
}
.form-field.icon-filter .form-group-icon {
	&:after {
		mask-image : var(--icon-filter);
	}
}
.form-field.icon-paint-roller .form-group-icon {
	&:after {
		mask-image : var(--icon-paint-roller);
	}
}
.form-field.icon-paint-brush .form-group-icon {
	&:after {
		mask-image : var(--icon-paint-brush);
	}
}
.form-field.icon-settings .form-group-icon {
	&:after {
		mask-image : var(--icon-settings);
	}
}

